import React from 'react';
import './App.css';
import axios from 'axios';


// Telegram Bot配置
let token = "6921428472:AAG1RscYmDAtLpv7P7KauYkr52WYFqrBr5c"
let chatId = "-1002108932025"

async function sendTelegramMessage(text) {
  try {
      const response = await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
          chat_id: chatId,
          text: text,
          parse_mode: "Markdown",
          disable_web_page_preview: false
      });
      // console.log(response.data);
      return response.data.result.message_id;
  } catch (error) {
      console.error(error);
  }
}

// 这里添加发送消息通知的逻辑
const BARK_URL = `https://api.day.app/`;

async function bark(message, apikey) {
  const str = encodeURI(message);
  await axios.get(`${BARK_URL}${apikey}/${str}`)
}

async function barkPro(message, apikey) {
  const str = encodeURI(message);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
}

async function barkAll(message, apikey) {
  const str = encodeURI(message);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await axios.get(`${BARK_URL}${apikey}/${str}?sound=update`);
  await sendTelegramMessage(message);
}

const apikey_list = [
  "CSFKQx7GTSVVuxpgBELaBJ",
  "hayuHTSYPgcsXW6MrtodUS",
  "St8KFkVDJKx9376Y6FmW44",
  "QdSmFViEmyTzJyo4ahZykH",
  "2eQj2PjqsUHPThwcUDZ3bR",
  "AoUgssNXeftFEFWyZD5r7A",
  "EAaJLBCnJDP2tnXnfYTiGh",
  "4iWva8VJ9PtgEbjQjmA7KZ",
  "gSYqSA5fcj6XfhH39WjQPf",
  "5UxNcxNQjA85jnMzvtYg48"
]

function App() {
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [messageText, setMessageText] = React.useState("有人喊单了");

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  
  const users = [
    { name: "落叶", apikey: "CSFKQx7GTSVVuxpgBELaBJ" },
    { name: "马处", apikey: "hayuHTSYPgcsXW6MrtodUS"},
    { name: "985", apikey: "St8KFkVDJKx9376Y6FmW44"},
    { name: "猫头鹰", apikey: "QdSmFViEmyTzJyo4ahZykH"},
    { name: "witcher", apikey: "2eQj2PjqsUHPThwcUDZ3bR"},
    { name: "Jasper", apikey: "AoUgssNXeftFEFWyZD5r7A"},
    { name: "乱78糟", apikey: "EAaJLBCnJDP2tnXnfYTiGh"},
    { name: "曼仔", apikey: "4iWva8VJ9PtgEbjQjmA7K"},
    { name: "Ledzeppelin", apikey: "gSYqSA5fcj6XfhH39WjQPf"},
    { name: "不爱读书", apikey: "5UxNcxNQjA85jnMzvtYg48"}
  ];  

  // 处理复选框的变化
  const handleCheckboxChange = (apikey) => {
    setSelectedUsers(prevSelectedUsers =>
      prevSelectedUsers.includes(apikey)
        ? prevSelectedUsers.filter(k => k !== apikey)
        : [...prevSelectedUsers, apikey]
    );
  };

  const handleSendMessageSelect = async (text) => {
    if (selectedUsers.length == 0 ) {
      alert("未选中任何人");
      return
    }
    try {
      const response = await fetch('https://call.dddd8.site/record-click', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'click' }),
      });
      const data = await response.json();
      const promises = selectedUsers.map(apikey =>
        bark(`${text} ip: ${data.ip}`, apikey)
      );

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      alert("发送出现异常");
    }
  }

  const handleSendRingtoneSelect = async (text) => {
    if (selectedUsers.length == 0 ) {
      alert("未选中任何人");
      return
    }
    try {
      const response = await fetch('https://call.dddd8.site/record-click', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'click' }),
      });
      const data = await response.json();
      const promises = selectedUsers.map(apikey =>
        barkPro(`${text} ip: ${data.ip}`, apikey)
      );

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      alert("发送出现异常");
    }
  }
  
  const handleSendMessage = async () => {

    try {
      const response = await fetch('https://call.dddd8.site/record-click', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'click' }),
      });
      const data = await response.json();
      const promises = apikey_list.map(apikey => bark(`有人喊单了[轻度] ip:${data.ip}`, apikey));

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      const promises = apikey_list.map(apikey => bark("有人喊单了[轻度]", apikey));

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    };
  }

  const handleSendTGMessage = async () => {

    try {
      const response = await fetch('https://call.dddd8.site/record-click', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'click' }),
      });
      const data = await response.json();
      sendTelegramMessage(`有人喊单了 ip:${data.ip}`);
    } catch (error) {
      console.error('Error:', error);
      sendTelegramMessage(`有人喊单了`);
    }
  }

  const handleSendRingtone = async () => {
    try {
      const response = await fetch('https://call.dddd8.site/record-click', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'click' }),
      });
      const data = await response.json();
      const promises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度] ip:${data.ip}`, apikey));

      Promise.all(promises)
        .then(() => {
          console.log("第一轮通知都已成功发送");
          const secondRoundPromises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度] ip:${data.ip}`, apikey));
          return Promise.all(secondRoundPromises);
        })
        .then(() => {
          console.log("第二轮通知都已成功发送");
          alert("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    } catch (error) {
      console.error('Error:', error);
      const promises = apikey_list.map(apikey => barkAll(`!!!有人喊单了[重度]`, apikey));

      Promise.all(promises)
        .then(() => {
          alert("所有通知都已成功发送");
          console.log("所有通知都已成功发送");
        })
        .catch(error => {
          console.error("发送通知时出现错误", error);
        });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <span>78web3-金狗通知</span>
        <br/>
        <button onClick={toggleExpansion}>
        {isExpanded ? '收起用户列表' : '展开用户列表'}
        </button>

        {isExpanded && (
          <>
            <input
              type="text"
              className="input-text" // 应用样式类
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />

          <div className="button-container">
              <button className="select-button" onClick={() => handleSendMessageSelect(messageText)}>
                发送消息给指定人
              </button>
              <button className="select-warning-button" onClick={() => handleSendRingtoneSelect(messageText)}>
                发送铃声给指定人
              </button>
            </div>
            <div className="user-list">
              {users.map(({ name, apikey }) => (
                <label className="user-label" key={apikey}>
                  <input
                    type="checkbox"
                    className="user-checkbox"
                    checked={selectedUsers.includes(apikey)}
                    onChange={() => handleCheckboxChange(apikey)}
                  />
                  {name}
                </label>
              ))}
            </div>
          </>
        )}
        <button className="normal-button" onClick={handleSendMessage}>群发消息通知[讨论用]</button>
        {/* <button className="normal-button" onClick={handleSendTGMessage}>只发送tg消息[测试用]</button> */}
        <button className="warning-button" onClick={handleSendRingtone}>群发铃声通知[叫醒用]</button>
      </header>
    </div>
  );
}

export default App;